import Header from "../../components/Header/index";
import Footer from "../../components/Footer/index";
import styles from "./FeaturePage.module.css";
// import NorthEastIcon from "@mui/icons-material/NorthEast";
// import { Link } from "react-router-dom";
import { Grid } from "@mui/material";
import { TabContext, TabPanel } from "@mui/lab";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { useState } from "react";
// import PriceCard from "../../components/Pricing/components/PriceCard";
import AllFeatures from "./components/AllFeatures";
import FeatureImg from "../../assets/images/featureimg.png";
import classImg from "../../assets/images/classimage.PNG";
import subjectimg from "../../assets/images/subjectimage.PNG";
import enrolment from "../../assets/images/enrolmentimage.PNG";
import lesson from "../../assets/images/lessonimage.PNG";
import schoolplan from "../../assets/images/schoolimage.PNG";
import leave from "../../assets/images/leaveimage.PNG";
import integration from "../../assets/images/integration.PNG";

const index = () => {
  const [value, setValue] = useState("1");

  const handleChange = (_event, newValue) => {
    setValue(newValue);
  };

  return (
    <div>
      <Header />
      <div className={`${styles.containerbcg}`}>
        <div className={`${styles.container}`}>
          <div className={`${styles.contmargin}`}>
            <div className={`${styles.requestheader}`}>
              <Grid container alignItems={"center"}>
                <Grid item lg={9} md={9} sm={9} xs={9}>
                  <h3>Shelf 21 Features</h3>
                  <p>
                    See the list of features you have access to as a premium
                    user of shelf 21. Empowering Schools, Engaging Students, and
                    Connecting Parents, all in one platform.
                  </p>
                </Grid>
                {/* <Grid item lg={3} md={3} sm={3} xs={4}>
                  <div
                    className={`${styles.explore} flex align-item-center gap-8 justify-end`}
                  >
                    <Link to="https://st-app.shelf21.com/auth/signup" target="_blank">Sign up to explore all</Link>
                    <div>
                      <NorthEastIcon className="light-blue margin-top-10" />
                    </div>
                  </div>
                </Grid> */}
              </Grid>
            </div>
            <div>
              <TabContext value={value}>
                <Box
                  sx={{
                    maxWidth: "787px",
                    marginTop: "54px",
                    bgcolor: "#E6F0FF",
                    borderRadius: "8px",
                    padding: "8px",
                    marginBottom: "30px",
                    color: "#0065FF"
                  }}
                >
                  <Tabs
                    onChange={handleChange}
                    TabIndicatorProps={{
                      style: { backgroundColor: "#0065FF", color: "white" }
                    }}
                    value={value}
                    centered
                    sx={{
                      "& MuiTabs": {
                        justifyContent: "space-between !important"
                      },
                      "& button": {
                        color: "#0065FF",
                        fontSize: "20px",
                        fontWeight: "700",
                        padding: "0px",
                        width: "243px"
                      },
                      "& button:hover": {
                        backgroundColor: "#0065FF",
                        color: "#FFF",
                        transition: "1s",
                        borderRadius: "8px"
                      },
                      "& button::active": {
                        backgroundColor: "#0065FF",
                        color: "#FFF",
                        transition: "1s",
                        borderRadius: "8px"
                      },
                      "& button:focus": {
                        backgroundColor: "#0065FF",
                        color: "#FFF",
                        transition: "1s",
                        borderRadius: "8px"
                      },
                      "& button:not(:last-child)": {
                        marginRight: "8px"
                      }
                    }}
                  >
                    <Tab
                      sx={{
                        textTransform: "capitalize",
                        "& .MuiTab-label": { textTransform: "lowercase" }
                      }}
                      label="All Features"
                      value="1"
                    />
                    <Tab
                      sx={{
                        textTransform: "capitalize",
                        "& .MuiTab-label": { textTransform: "lowercase" }
                      }}
                      label="Adminstrative Feature"
                      value="2"
                    />
                    <Tab
                      sx={{
                        textTransform: "capitalize",
                        "& .MuiTab-label": { textTransform: "lowercase" }
                      }}
                      label="Academic Features"
                      value="3"
                    />
                  </Tabs>
                </Box>
                <TabPanel
                  sx={{ padding: "0px", marginBottom: "40px" }}
                  value="1"
                >
                  <AllFeatures
                    backgroundColor="#E6F0FF"
                    fontColor="#0065FF"
                    title="FEATURE"
                    image={FeatureImg}
                    featuretitle="Attendance and Roll-call"
                    featuredesc="Empowering Schools, Engaging Students, and Connecting Parents, all in one platform. Empowering Schools, Engaging Students, and Connecting Parents, all in one platform."
                    features={[
                      "Get daily student Attendance",
                      "Send Attendance report to parents",
                      "Overview of overall attendance record of students"
                    ]}
                  />
                  <AllFeatures
                    backgroundColor="#FEF6E8"
                    fontColor="#F3A218"
                    title="FEATURE"
                    image={lesson}
                    featuretitle="Lesson Notes"
                    features={[
                      "Create lesson notes for each class.",
                      "Organize topics and teaching methods for efficient lesson planning.",
                      "Review and update notes anytime."
                    ]}
                  />
                  <AllFeatures
                    backgroundColor="#E7F5EC"
                    fontColor="#0F973D"
                    title="FEATURE"
                    image={leave}
                    featuretitle="Leave Management"
                    features={[
                      "Manage employee leave requests effortlessly",
                      "Provide multiple leave options for staff",
                      "Track leave statuses in real-time"
                    ]}
                  />
                  <AllFeatures
                    backgroundColor="#F5F6F7"
                    fontColor="#98A2B3"
                    title="FEATURE"
                    image={FeatureImg}
                    featuretitle="Assessment"
                    features={[
                      "Assignments: View all ongoing and submitted assignments.",
                      "Examinations: Access pending exam papers awaiting approval.",
                      "Set custom grading systems for your school."
                    ]}
                  />
                  <AllFeatures
                    backgroundColor="#E7F5EC"
                    fontColor="#0F973D"
                    title="FEATURE"
                    image={schoolplan}
                    featuretitle="School Plans"
                    features={[
                      "School Terms: Manage and adjust your school's terms.",
                      "School Sessions: Create and oversee sessions with ease.",
                      "School Calendar: Set student plans and create new schedules."
                    ]}
                  />
                  <AllFeatures
                    backgroundColor="#FEF2F2"
                    fontColor="#E77B7B"
                    title="FEATURE"
                    image={enrolment}
                    featuretitle="School Enrollments"
                    features={[
                      "Manage student applications and enrollment processes smoothly.",
                      "View and track all ongoing applications."
                    ]}
                  />
                  <AllFeatures
                    backgroundColor="#E6F0FF"
                    fontColor="#013EBA"
                    title="FEATURE"
                    featuretitle="Integrations"
                    image={integration}
                    features={[
                      "Seamlessly integrate with payment platforms like Paystack, Stripe, Flutterwave, and PayPal.",
                      "Connect to Mailchimp for streamlined communication."
                    ]}
                  />
                  <AllFeatures
                    backgroundColor="#FEF6E8"
                    fontColor="#F3A218"
                    title="FEATURE"
                    image={subjectimg}
                    featuretitle="Subject"
                    features={[
                      "Track all subjects currently offered.",
                      "Assign teachers to specific subjects with ease."

                    ]}
                  />
                  <AllFeatures
                    backgroundColor="#F5F6F7"
                    fontColor="#98A2B3"
                    title="FEATURE"
                    image={classImg}
                    featuretitle="Class Activities"
                    features={[
                      "View and manage all classes in your school. Add or remove classes anytime."
                    ]}
                  />
                </TabPanel>
                <TabPanel sx={{ padding: "0px", marginBottom: "40px" }}
                  value="2"
                >
                  <AllFeatures
                    backgroundColor="#FEF2F2"
                    fontColor="#E77B7B"
                    title="FEATURE"
                    image={leave}
                    featuretitle="Leave Management"
                    features={[
                      "Manage employee leave requests effortlessly",
                      "Provide multiple leave options for staff",
                      "Track leave statuses in real-time"
                    ]}
                  />
                  <AllFeatures
                    backgroundColor="#E7F5EC"
                    fontColor="#0F973D"
                    title="FEATURE"
                    image={FeatureImg}
                    featuretitle="Assessment"
                    features={[
                      "Assignments: View all ongoing and submitted assignments.",
                      "Examinations: Access pending exam papers awaiting approval.",
                      "Set custom grading systems for your school."
                    ]}
                  />
                  <AllFeatures
                    backgroundColor="#FEF2F2"
                    fontColor="#E77B7B"
                    title="FEATURE"
                    image={schoolplan}
                    featuretitle="School Plans"
                    features={[
                      "School Terms: Manage and adjust your school's terms.",
                      "School Sessions: Create and oversee sessions with ease.",
                      "School Calendar: Set student plans and create new schedules."
                    ]}
                  />
                  <AllFeatures
                    backgroundColor="#F5F6F7"
                    fontColor="#98A2B3"
                    title="FEATURE"
                    image={enrolment}
                    featuretitle="School Enrollments"
                    features={[
                      "Manage student applications and enrollment processes smoothly.",
                      "View and track all ongoing applications."
                    ]}
                  />
                  <AllFeatures
                    backgroundColor="#FEF2F2"
                    fontColor="#E77B7B"
                    title="FEATURE"
                    image={integration}
                    featuretitle="Integrations"
                    features={[
                      "Seamlessly integrate with payment platforms like Paystack, Stripe, Flutterwave, and PayPal.",
                      "Connect to Mailchimp for streamlined communication."
                    ]}
                  />
                </TabPanel>
                <TabPanel sx={{ padding: "0px", marginBottom: "40px" }}
                  value="3"
                >
                  <AllFeatures
                    backgroundColor="#E6F0FF"
                    fontColor="#0065FF"
                    title="FEATURE"
                    image={FeatureImg}
                    featuretitle="Attendance and Roll-call"
                    featuredesc="Empowering Schools, Engaging Students, and Connecting Parents, all in one platform. Empowering Schools, Engaging Students, and Connecting Parents, all in one platform."
                    features={[
                      "Get daily student Attendance",
                      "Send Attendance report to parents",
                      "Overview of overall attendance record of students"
                    ]}
                  />
                  <AllFeatures
                    backgroundColor="#FEF6E8"
                    fontColor="#F3A218"
                    title="FEATURE"
                    image={lesson}
                    featuretitle="Lesson Notes"
                    features={[
                      "Create lesson notes for each class.",
                      "Organize topics and teaching methods for efficient lesson planning.",
                      "Review and update notes anytime."
                    ]}
                  />
                  <AllFeatures
                    backgroundColor="#FEF2F2"
                    fontColor="#E77B7B"
                    title="FEATURE"
                    image={subjectimg}
                    featuretitle="Subject"
                    features={[
                      "Track all subjects currently offered.",
                      "Assign teachers to specific subjects with ease."

                    ]}
                  />
                  <AllFeatures
                    backgroundColor="#F5F6F7"
                    fontColor="#98A2B3"
                    title="FEATURE"
                    image={classImg}
                    featuretitle="Class Activities"
                    features={[
                      "View and manage all classes in your school. Add or remove classes anytime."
                    ]}
                  />
                </TabPanel>
              </TabContext>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default index;
